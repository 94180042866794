import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import {
  H,
  P,
  Wrapper,
  Grid,
  Button,
  BulletSeparator,
  ArrowLeftIcon,
} from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import Avatar from 'components/Avatar'
import ArticleCard from 'components/ArticleCard'
import { normalizeArray } from 'lib/graphql/normalize'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import PATHS from 'paths'
import { ROOT_URL } from 'config'

const StyledGridItem = styled(Grid.Item)`
  margin-bottom: ${GTR.S};
`

const StyledBulletSeparator = styled(BulletSeparator)`
  margin-top: ${GTR.M};
`

const StyledStrongText = styled.strong`
  color: ${COLOR.BLACK};
`

const Author = ({ data }) => {
  const { author } = data
  const { twitterLink, linkedInLink } = author
  const url = `${ROOT_URL}/authors/${author.slug}`
  const articles = normalizeArray(data.articles)
  return (
    <>
      <JSONLD
        data={{
          '@context': 'http://schema.org',
          '@type': 'Person',
          name: author.name,
          sameAs: [linkedInLink, twitterLink].filter((l) => !!l),
          jobTitle: author.title,
          hasCredential: author.qualifications
            ? {
                '@type': 'EducationalOccupationalCredential',
                credentialCategory: author.qualifications,
              }
            : {},
          worksFor: author.title && FAREWILL_JSONLD,
          image: author.image &&
            author.image.file && {
              '@type': 'imageObject',
              url: `https:${author.image.file.url}?w=1200`,
              width: 1200,
            },
          url,
        }}
      />
      <DefaultLayout
        title={author.name}
        description={author?.biography?.biography}
      >
        <Wrapper container containerPaddingBottom={0}>
          <Grid>
            <Grid.Item spanFromM={9} spanFromL={7}>
              <Avatar
                imageSrc={author?.image?.file?.url}
                size={90}
                alt={author.name}
              />
              <H size="XL" tag="h1" decorative margin={['L', 'auto', 'S']}>
                {author.name}
              </H>

              <BulletSeparator>
                <StyledStrongText>{author.title}</StyledStrongText>
                {author.qualifications && (
                  <StyledStrongText>{author.qualifications}</StyledStrongText>
                )}
              </BulletSeparator>

              <Wrapper margin={['XS', 'auto', 0]}>
                {author?.biography?.biography}
              </Wrapper>
              {(twitterLink || linkedInLink) && (
                <StyledBulletSeparator>
                  {twitterLink && <a href={twitterLink}>Twitter</a>}
                  {linkedInLink && <a href={linkedInLink}>LinkedIn</a>}
                </StyledBulletSeparator>
              )}
            </Grid.Item>
          </Grid>
          <Grid padding={['L', 0]}>
            {articles.map((article) => (
              <StyledGridItem key={article.id} spanFromM={6} spanFromL={4}>
                <ArticleCard
                  type={article.type}
                  title={article.title}
                  slug={article.slug}
                  image={article.image}
                  description={
                    article.description && article.description.description
                  }
                  publishedAt={article.publishedAt}
                />
              </StyledGridItem>
            ))}
          </Grid>
        </Wrapper>
        <P container containerPaddingTop="M">
          <Button.Bordered
            to={PATHS.GENERAL.AUTHORS}
            tag={Link}
            icon={ArrowLeftIcon}
            iconOnLeft
          >
            View all authors
          </Button.Bordered>
        </P>
      </DefaultLayout>
    </>
  )
}

Author.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      qualifications: PropTypes.string,
      title: PropTypes.string,
      twitterLink: PropTypes.string,
      linkedInLink: PropTypes.string,
      biography: PropTypes.shape({
        biography: PropTypes.string.isRequired,
      }),
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
      }),
    }).isRequired,
    articles: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            image: PropTypes.shape({
              file: PropTypes.shape({
                url: PropTypes.string.isRequired,
              }).isRequired,
              title: PropTypes.string.isRequired,
            }).isRequired,
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            publishedAt: PropTypes.string.isRequired,
            description: PropTypes.shape({
              description: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query AuthorByName($authorSlug: String!) {
    author: contentfulTeamMember(slug: { eq: $authorSlug }) {
      name
      slug
      qualifications
      title
      twitterLink
      linkedInLink
      biography {
        biography
      }
      image {
        file {
          url
        }
        title
      }
    }
    articles: allContentfulArticle(
      filter: { author: { slug: { eq: $authorSlug } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          image {
            file {
              url
            }
            title
          }
          type
          id
          publishedAt
          description {
            description
          }
        }
      }
    }
  }
`

export default Author
